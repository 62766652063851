<template>
  <div id="data-analytic-dashboard" class="site-container">
    <div v-if="isLoading" class="loading-screen">
      <div class="loading-container">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </div>
    <template v-else>
      <div v-if="chartDataAnalytic" class="wp-data-analytic">
        <div class="data-analytic-general">
          <div class="col-12">
            <div class="row custom-height">
              <div class="col-4 customize-col-4 space--right">
                <div class="site-information-form">
                  <div class="site-information-content">
                    <div class="site-information-detail">
                      <div class="detail-desc">
                        <div class="row-info-space">
                          <p v-if="chartDataAnalytic?.site" class="project-address">{{ chartDataAnalytic.site.global }} / {{ chartDataAnalytic.site.regional }} / {{ chartDataAnalytic.site.area }} / {{ chartDataAnalytic.site.project }}</p>
                        </div>
                        <div class="row-info-space">
                          <p class="detail-desc-title">SITE ID</p>
                          <p class="detail-desc-content">{{ chartDataAnalytic.site_id ? chartDataAnalytic.site_id : '' }}</p>
                        </div>
                        <div class="row-info-space">
                          <p class="detail-desc-title">SITE NAME</p>
                          <p class="detail-desc-content">{{ chartDataAnalytic.site_name ? chartDataAnalytic.site_name : '' }}</p>
                        </div>
                        <div class="row-info-space">
                          <p class="detail-desc-title">COMMISSIONED DATE</p>
                          <p class="detail-desc-content">{{ chartDataAnalytic.commissioned_date ? MOMENT(chartDataAnalytic.commissioned_date, "DD/MM/YYYY") : '' }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="menu-filters-wp">
                  <div class="date-type-filters">
                    <v-btn-toggle v-model="selectedFilterDateType" @change="updateFilterDateType" mandatory>
                      <v-btn value="day">DAY</v-btn>
                      <v-btn value="month">MONTH</v-btn>
                      <v-btn value="year">YEAR</v-btn>
                      <v-btn value="life_time">LIFETIME</v-btn>
                    </v-btn-toggle>
                  </div>
                  <div class="pick-date-filters">
                    <!-- DAY PICKER -->
                    <template v-if="['day'].includes(selectedFilterDateType)">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-top="0"
                        :nudge-right="170"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-row no-gutters class="date-picker-controls">
                            <v-btn icon class="prev-button" @click="prevDay">
                              <i class="fas fa-chevron-left"></i>
                            </v-btn>
                            <v-text-field
                              v-model="selectedDateText"
                              append-icon="fas fa-caret-down"
                              placeholder="Pick Date"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              class="select-date-analytic"
                            ></v-text-field>
                            <v-btn icon class="next-button" @click="nextDay" :disabled="disabledNextButton(selectedDate, 'YYYY-MM-DD')" :class="`${disabledNextButton(selectedDate, 'YYYY-MM-DD') ? 'can-not-click' : ''}`">
                              <i class="fas fa-chevron-right"></i>
                            </v-btn>
                          </v-row>
                        </template>
                        <v-date-picker
                          v-model="selectedDate"
                          :max="NOW_DATE().format(DATE_PICKER_FORMAT)"
                          no-title
                          type="date"
                          @change="reloadAreaChart('date')"
                          class="date-analytic-table"
                        >
                        </v-date-picker>
                      </v-menu>
                    </template>
                    <!-- MONTH PICKER -->
                    <template v-if="['month'].includes(selectedFilterDateType)">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-top="0"
                        :nudge-right="170"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-row no-gutters class="date-picker-controls">
                            <v-btn icon class="prev-button" @click="prevMonth">
                              <i class="fas fa-chevron-left"></i>
                            </v-btn>
                            <v-text-field
                              v-model="selectedMonthText"
                              append-icon="fas fa-caret-down"
                              placeholder="Pick Month"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              class="select-date-analytic select-month-width"
                            ></v-text-field>
                            <v-btn icon class="next-button" @click="nextMonth" :disabled="disabledNextButton(selectedMonth, 'YYYY-MM')" :class="`${disabledNextButton(selectedMonth, 'YYYY-MM') ? 'can-not-click' : ''}`">
                              <i class="fas fa-chevron-right"></i>
                            </v-btn>
                          </v-row>
                        </template>
                        <v-date-picker
                          v-model="selectedMonth"
                          :max="NOW_DATE().format(DATE_PICKER_FORMAT)"
                          no-title
                          type="month"
                          @change="reloadAreaChart('month')"
                          class="date-analytic-table"
                        >
                        </v-date-picker>
                      </v-menu>
                    </template>
                    <!-- YEAR PICKER -->
                    <template v-if="['year'].includes(selectedFilterDateType)">
                      <v-row no-gutters class="date-picker-controls">
                        <v-btn icon class="prev-button" @click="prevYear" :disabled="selectedYear <= 2020" :class="`${selectedYear <= 2020 ? 'can-not-click' : ''}`">
                          <i class="fas fa-chevron-left"></i>
                        </v-btn>
                        <v-select
                          :items="yearsList"
                          v-model="selectedYear"
                          filled
                          :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_menu_year custom_active_item' }"
                          append-icon="fas fa-caret-down"
                          class="custom-select-year"
                          @change="reloadAreaChart"
                        ></v-select>
                        <v-btn icon class="next-button" @click="nextYear" :disabled="disabledNextButton(selectedYear, 'YYYY')" :class="`${disabledNextButton(selectedYear, 'YYYY') ? 'can-not-click' : ''}`">
                          <i class="fas fa-chevron-right"></i>
                        </v-btn>
                      </v-row>
                    </template>
                    <!-- LIFETIME -->
                    <template v-if="['life_time'].includes(selectedFilterDateType)">
                      <v-row no-gutters class="date-picker-controls" style="pointer-events: none;">
                        <v-btn icon class="prev-button can-not-click">
                          <i class="fas fa-chevron-left"></i>
                        </v-btn>
                        <v-text-field
                          v-model="selectedLifeTimeText"
                          append-icon="fas fa-caret-down"
                          placeholder="Pick LifeTime"
                          readonly
                          class="select-date-analytic select-lifetime-width"
                        ></v-text-field>
                        <v-btn icon class="next-button can-not-click">
                          <i class="fas fa-chevron-right"></i>
                        </v-btn>
                      </v-row>
                    </template>
                  </div>

                  <div class="device-type-filters">
                    <v-btn-toggle v-model="selectedFilterDeviceType" @change="updateFilterDeviceType" mandatory>
                      <v-btn class="pv" value="pv">
                        <img src="@/assets/pages/data-analytic/pv-v2.svg" />
                      </v-btn>
                      <v-btn class="bess" value="bess">
                        <img src="@/assets/pages/data-analytic/bess_item-v2.svg" />
                      </v-btn>
                      <v-btn class="grid" value="grid">
                        <img src="@/assets/pages/data-analytic/grid_item-v2.svg" />
                      </v-btn>
                      <v-btn class="evse" value="evse">
                        <img src="@/assets/pages/data-analytic/evse_item-v2.svg" />
                      </v-btn>
                      <v-btn class="home_load" value="home_load">
                        <img src="@/assets/pages/data-analytic/home_load_item-v2.svg" />
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                </div>
                <div class="total-sites-chart total-sites-alarm">
                  <div class="col-12 total-sites-chart-content">
                    <div class="total-sites-chart-heading">
                    <h2 class="total-sites-chart--title">SUMMARY</h2>
                  </div>
                    <div class="row align-items-center h-100">
                      <div class="col-lg-7 col-md-6 p-0">
                        <div class="donut-chart" v-if="hasValueGreaterThanZero(summarySeries)">
                          <VueApexCharts
                            v-if="summarySeries.length > 0" 
                            type="donut" 
                            :options="summaryChartOptions" 
                            :series="summarySeries"
                          />
                          <div class="total-sites-title">
                            <p class="title-desc-name">{{ donutTotalNameKeys[chartDataAnalytic.total_key] }}</p>
                            <strong class="title-desc-num">{{ chartDataAnalytic.total_value != null ? convertEnergyValue(chartDataAnalytic.total_value) : '−−' }}</strong>
                            <p class="title-desc-unit">{{ convertEnergyUnit(chartDataAnalytic.total_value) }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-5 col-md-6 p-0">
                        <!-- PV LEGEND: -->
                        <div class="alarm-summary-legend" v-if="selectedFilterDeviceType === 'pv'">
                          <div class="sites-legend">
                            <div class="sites-legend-images home_load">
                              <img src="@/assets/pages/data-analytic/home_load_item-v2_38x38.svg" />
                            </div>
                            <div class="sites-legend-desc">
                              <div class="legend-desc-value">
                                <strong class="legend-desc-num">{{ chartDataAnalytic.summary[0].value !== null ? formatDecimalNumber(chartDataAnalytic.summary[0].value) : '−−' }}</strong>
                                <span class="legend-desc-unit">%</span>
                              </div>
                              <p class="legend-desc-status">House Load</p>
                            </div>
                          </div>
                          <div class="sites-legend">
                            <div class="sites-legend-images evse">
                              <img src="@/assets/pages/data-analytic/evse_item-v2_38x38.svg" />
                            </div>
                            <div class="sites-legend-desc">
                              <div class="legend-desc-value">
                                <strong class="legend-desc-num">{{ chartDataAnalytic.summary[1].value !== null ? formatDecimalNumber(chartDataAnalytic.summary[1].value) : '−−' }}</strong>
                                <span class="legend-desc-unit">%</span>
                              </div>
                              <p class="legend-desc-status">EV Charger</p>
                            </div>
                          </div>
                          <div class="sites-legend">
                            <div class="sites-legend-images bess">
                              <img src="@/assets/pages/data-analytic/bess_item-v2_38x38.svg" />
                            </div>
                            <div class="sites-legend-desc">
                              <div class="legend-desc-value">
                                <strong class="legend-desc-num">{{ chartDataAnalytic.summary[2].value !== null ? formatDecimalNumber(chartDataAnalytic.summary[2].value) : '−−' }}</strong>
                                <span class="legend-desc-unit">%</span>
                              </div>
                              <p class="legend-desc-status">Battery Charge</p>
                            </div>
                          </div>
                          <div class="sites-legend">
                            <div class="sites-legend-images grid">
                              <img src="@/assets/pages/data-analytic/grid_item-v2_38x38.svg" />
                            </div>
                            <div class="sites-legend-desc">
                              <div class="legend-desc-value">
                                <strong class="legend-desc-num">{{ chartDataAnalytic.summary[3].value !== null ? formatDecimalNumber(chartDataAnalytic.summary[3].value) : '−−'}}</strong>
                                <span class="legend-desc-unit">%</span>
                              </div>
                              <p class="legend-desc-status">Feed to Grid</p>
                            </div>
                          </div>
                        </div>
                        <!-- BESS LEGEND: -->
                        <div class="alarm-summary-legend" v-if="selectedFilterDeviceType === 'bess'">
                          <div class="sites-legend">
                            <div class="sites-legend-images summary_battery_import">
                              <img src="@/assets/pages/data-analytic/summary_battery_import.svg" />
                            </div>
                            <div class="sites-legend-desc">
                              <div class="legend-desc-value">
                                <strong class="legend-desc-num">{{ chartDataAnalytic.summary[0].value !== null ? convertEnergyValue(chartDataAnalytic.summary[0].value) : '−−' }}</strong>
                                <span class="legend-desc-unit">{{ convertEnergyUnit(chartDataAnalytic.summary[0].value) }}</span>
                              </div>
                              <p class="legend-desc-status">Battery Charge</p>
                            </div>
                          </div>
                          <div class="sites-legend">
                            <div class="sites-legend-images summary_battery_export">
                              <img src="@/assets/pages/data-analytic/summary_battery_export.svg" />
                            </div>
                            <div class="sites-legend-desc">
                              <div class="legend-desc-value">
                                <strong class="legend-desc-num">{{ chartDataAnalytic.summary[1].value !== null ? convertEnergyValue(chartDataAnalytic.summary[1].value) : '−−' }}</strong>
                                <span class="legend-desc-unit">{{ convertEnergyUnit(chartDataAnalytic.summary[1].value) }}</span>
                              </div>
                              <p class="legend-desc-status">Battery Discharge</p>
                            </div>
                          </div>
                        </div>
                        <!-- GRID LEGEND: -->
                        <div class="alarm-summary-legend" v-if="selectedFilterDeviceType === 'grid'">
                          <div class="sites-legend">
                            <div class="sites-legend-images sumary_grid">
                              <img src="@/assets/pages/data-analytic/sumary_grid.svg" />
                            </div>
                            <div class="sites-legend-desc">
                              <div class="legend-desc-value">
                                <strong class="legend-desc-num">{{chartDataAnalytic.summary[0].value !== null ? convertEnergyValue(chartDataAnalytic.summary[0].value) : '−−' }}</strong>
                                <span class="legend-desc-unit">{{ convertEnergyUnit(chartDataAnalytic.summary[0].value) }}</span>
                              </div>
                              <p class="legend-desc-status">Grid Import</p>
                            </div>
                          </div>
                          <div class="sites-legend">
                            <div class="sites-legend-images sumary_grid">
                              <img src="@/assets/pages/data-analytic/sumary_grid-v2.svg" />
                            </div>
                            <div class="sites-legend-desc">
                              <div class="legend-desc-value">
                                <strong class="legend-desc-num">{{chartDataAnalytic.summary[1].value !== null ? convertEnergyValue(chartDataAnalytic.summary[1].value) : '−−' }}</strong>
                                <span class="legend-desc-unit">{{ convertEnergyUnit(chartDataAnalytic.summary[1].value) }}</span>
                              </div>
                              <p class="legend-desc-status">Grid Export</p>
                            </div>
                          </div>
                        </div>
                        <!-- EVSE LEGEND: -->
                        <div class="alarm-summary-legend" v-if="selectedFilterDeviceType === 'evse'">
                          <div class="sites-legend">
                            <div class="sites-legend-images summary_pv">
                              <img src="@/assets/pages/data-analytic/summary_pv.svg" />
                            </div>
                            <div class="sites-legend-desc">
                              <div class="legend-desc-value">
                                <strong class="legend-desc-num">{{chartDataAnalytic.summary[0].value !== null ? formatDecimalNumber(chartDataAnalytic.summary[0].value) : '−−' }}</strong>
                                <span class="legend-desc-unit">%</span>
                              </div>
                              <p class="legend-desc-status">PV (Self-Sufficiency)</p>
                            </div>
                          </div>
                          <div class="sites-legend">
                            <div class="sites-legend-images sumary_battery_charge">
                              <img src="@/assets/pages/data-analytic/sumary_battery_charge.svg" />
                            </div>
                            <div class="sites-legend-desc">
                              <div class="legend-desc-value">
                                <strong class="legend-desc-num">{{chartDataAnalytic.summary[1].value !== null ? formatDecimalNumber(chartDataAnalytic.summary[1].value) : '−−' }}</strong>
                                <span class="legend-desc-unit">%</span>
                              </div>
                              <p class="legend-desc-status">Battery Discharge</p>
                            </div>
                          </div>
                          <div class="sites-legend">
                            <div class="sites-legend-images sumary_grid">
                              <img src="@/assets/pages/data-analytic/sumary_grid.svg" />
                            </div>
                            <div class="sites-legend-desc">
                              <div class="legend-desc-value">
                                <strong class="legend-desc-num">{{chartDataAnalytic.summary[2].value !== null ? formatDecimalNumber(chartDataAnalytic.summary[2].value) : '−−' }}</strong>
                                <span class="legend-desc-unit">%</span>
                              </div>
                              <p class="legend-desc-status">Grid</p>
                            </div>
                          </div>
                        </div>
                        <!-- HOME LOAD LEGEND: -->
                        <div class="alarm-summary-legend" v-if="selectedFilterDeviceType === 'home_load'">
                          <div class="sites-legend">
                            <div class="sites-legend-images summary_pv">
                              <img src="@/assets/pages/data-analytic/summary_pv.svg" />
                            </div>
                            <div class="sites-legend-desc">
                              <div class="legend-desc-value">
                                <strong class="legend-desc-num">{{chartDataAnalytic.summary[0].value !== null ? formatDecimalNumber(chartDataAnalytic.summary[0].value) : '−−' }}</strong>
                                <span class="legend-desc-unit">%</span>
                              </div>
                              <p class="legend-desc-status">PV (Self-Sufficiency)</p>
                            </div>
                          </div>
                          <div class="sites-legend">
                            <div class="sites-legend-images sumary_battery_charge">
                              <img src="@/assets/pages/data-analytic/sumary_battery_charge.svg" />
                            </div>
                            <div class="sites-legend-desc">
                              <div class="legend-desc-value">
                                <strong class="legend-desc-num">{{chartDataAnalytic.summary[1].value !== null ? formatDecimalNumber(chartDataAnalytic.summary[1].value) : '−−' }}</strong>
                                <span class="legend-desc-unit">%</span>
                              </div>
                              <p class="legend-desc-status">Battery Discharge</p>
                            </div>
                          </div>
                          <div class="sites-legend">
                            <div class="sites-legend-images sumary_grid">
                              <img src="@/assets/pages/data-analytic/sumary_grid.svg" />
                            </div>
                            <div class="sites-legend-desc">
                              <div class="legend-desc-value">
                                <strong class="legend-desc-num">{{chartDataAnalytic.summary[2].value !== null ? formatDecimalNumber(chartDataAnalytic.summary[2].value) : '−−' }}</strong>
                                <span class="legend-desc-unit">%</span>
                              </div>
                              <p class="legend-desc-status">Grid</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-8 customize-col-8 space--left">
                <div class="area-chart">
                  <div class="area-chart-heading">
                    <h2 class="area-chart-heading--title">{{ headingAreaChart[selectedFilterDeviceType] }}</h2>
                  </div>
                  <VueApexCharts
                    class="chart-container"
                    :type="configChartTypes[selectedFilterDateType]"
                    :height="chartHeight" 
                    :options="areaChartOptions" 
                    :series="areaSeries"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" src="@/assets/css/data-analytic-dashboard.scss"></style>

<script>
import VueApexCharts from "vue-apexcharts";
import { dataAnalyticService } from "@/services";
import moment from "moment";

export default {
  name: "DataAnalyticDashboard",
  components: { VueApexCharts, },
  data() {
    return {
      isLoading: true,
      summarySeries: [],
      summaryChartOptions: {},
      chartDataAnalytic: null,
      selectedFilterDateType: localStorage.getItem('filter_date_type_localstorage') || 'day',
      selectedFilterDeviceType: localStorage.getItem('filter_device_type_localstorage') || 'pv',
      selectedDate: moment().format("YYYY-MM-DD"),
      selectedMonth: moment().format("YYYY-MM"),
      selectedYear: moment().format("YYYY"),
      yearsList: [],
      areaSeries: [],
      areaChartOptions: {},
      xaxisAnalyticSeriesLabel: [],
      colorSummaryChart: {
        "pv": ['#7030A0', '#2E6BA4', '#4A8521', '#BEBEBE'], 
        "bess": ['#4A8521', '#86B593'], 
        "grid": ['#BFBFBF', '#7F7F7F'], 
        "evse": ['#DAA931', '#4A8521', '#BEBEBE'], 
        "home_load": ['#DAA931', '#4A8521', '#BEBEBE'], 
      },
      colorAreaChart: {
        "pv": ['#7030A0', '#2E6BA4', '#4A8521', '#BEBEBE', '#DAA931'], 
        "bess": ['#4A8521', '#86B593'], 
        "grid": ['#BFBFBF', '#7F7F7F'], 
        "evse": ['#DAA931', '#4A8521', '#BEBEBE', '#2E6BA4'], 
        "home_load": ['#DAA931', '#4A8521', '#BEBEBE', '#7030A0'], 
      },
      labelSummaryChart: {
        "pv": ["House Load", "EV Charger", "Battery Charge", "Feed to Grid"], 
        "bess": ["Battery Charge", "Battery Discharge"], 
        "grid": ["Grid Import", "Grid Export"], 
        "evse": ["PV (Self-Sufficiency)", "Battery Discharge", "Grid"], 
        "home_load": ["PV (Self-Sufficiency)", "Battery Discharge", "Grid"], 
      },
      headingAreaChart: {
        "pv": "SOLAR DISTRIBUTION", 
        "bess": "BATTERY DISTRIBUTION", 
        "grid": "GRID DISTRIBUTION", 
        "evse": "EV CHARGER DISTRIBUTION", 
        "home_load": "HOUSE LOAD DISTRIBUTION", 
      },
      configChartTypes: {
        "day": "area",
        "month": "bar",
        "year": "bar",
        "life_time": "bar",
      },
      configChartStacked: {
        "pv": true, 
        "bess": false, 
        "grid": false, 
        "evse": true, 
        "home_load": true, 
      },
      chartHeight: this.calculateChartHeight(),
      isFilterDayType: false,
      isFilterLifeTimeType: false,
      socketData: null,
      xNameAreaChartObj: {
        "pv": {
          "pv": "PV",
          "bess": "PV to Battery",
          "feed_to_grid": "PV to Grid",
          "evse": "PV to EV",
          "home_load": "PV to Load",
        },
        "bess": {
          "charge": "Battery Charger",
          "discharge": "Battery Discharge",
        },
        "grid": {
          "import": "Grid Import",
          "export": "Grid Export",
        },
        "evse": {
          "pv": "EV from PV",
          "bess": "EV from Battery",
          "grid": "EV from Grid",
          "evse": "EV",
        }, 
        "home_load": {
          "pv": "Load from PV",
          "bess": "PV from Battery",
          "grid": "Load from Grid",
          "home_load": "Load",
        }, 
      },
      selectedDateText: moment().format("DD/MM/YYYY"),
      selectedLifeTimeText: "SINCE " + moment().format("DD/MM/YYYY"),
      selectedMonthText: moment().format("MM/YYYY"),
      donutTotalNameKeys: {
        "generation": "Total Solar Generation", 
        "net_discharge": "Net Discharge", 
        "net_charge": "Net Charge", 
        "consumption": "Total Consumption",
        "net_imported": "Net Import",
        "net_exported": "Net Export"
      },
    };
  },
  async created() {
    await this.reloadAreaChart();
    this.selectedLifeTimeText = this.chartDataAnalytic?.commissioned_date ? "SINCE " + moment(this.chartDataAnalytic.commissioned_date).format('DD/MM/YYYY') : this.selectedLifeTimeText;
  },
  mounted() {
    this.yearsList = this.generateYearsList(2020);
    this.socketData = new WebSocket(this.WEB_SOCKET_URL);
    this.socketData.onmessage = (event) => {
      const socketEventData = JSON.parse(event.data);
      if(socketEventData.action === "get_data_analytic") {
        if(socketEventData.data.device_type === this.selectedFilterDeviceType && socketEventData.data.date === this.selectedDate && this.selectedFilterDateType === "day" && socketEventData.data.site_id == this.$store.state.siteId) {
          this.chartDataAnalytic = { ...this.chartDataAnalytic, ...socketEventData.data };
          this.resetAnalyticChart();
          this.setChartShapes();
        }
      }
    };
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    getSeriesDataByMinutes (series_data) {
      const data = [];
      const current_date = new Date();
      current_date.setUTCHours(0, 0, 0, 0);
      for (let minute = 0; minute < 60 * 24; minute++) {
        const timestamp = current_date.getTime();
        const series_value = series_data[minute % series_data.length];
        if (series_value !== null) {
          data.push([timestamp, series_value]);
        }
        current_date.setMinutes(current_date.getMinutes() + 1);
      }
      return data;
    },
    calculateChartHeight() {
      let chart_height = 700;
      if(window.innerWidth >= 2048) {
        if(window.innerHeight == 1536) {
          chart_height = 1270;
        } else {
          chart_height = 1150;
        }
      } else if(window.innerWidth >= 1920) {
        if(window.innerHeight == 1200) {
          chart_height = 980;
        } else if(window.innerHeight == 1080) {
          chart_height = 860;
        } 
      } else if (window.innerWidth <= 1440 && window.innerWidth > 1366) {
        if (window.innerHeight == 900) {
          chart_height = 700;
        } else {
          chart_height = 860;
        }
      } else if (window.innerWidth <= 1194) {
        chart_height = 620;
      } else {
          chart_height = 540;
      }
      return chart_height;
    },
    handleResize() {
      this.chartHeight = this.calculateChartHeight();
    },
    disabledNextButton(time, format) {
      return moment().format(format) <= moment(time).format(format);
    },
    generateYearsList(startYear) {
      const years = [];
      const startMoment = moment({ year: startYear });
      const endMoment = moment();
      while (endMoment.isSameOrAfter(startMoment)) {
        years.push(endMoment.format("YYYY"));
        endMoment.subtract(1, 'years');
      }
      return years;
    },
    prevDay() {
      if(this.selectedDate && this.selectedFilterDateType === 'day') {
        this.selectedDate = moment(this.selectedDate).subtract(1, 'days').format("YYYY-MM-DD");
      }
      this.reloadAreaChart("date");
    },
    nextDay() {
      if(this.selectedDate && this.selectedFilterDateType === 'day') {
        this.selectedDate = moment(this.selectedDate).add(1, 'days').format("YYYY-MM-DD");
      }
      this.reloadAreaChart("date");
    },
    prevMonth() {
      if(this.selectedMonth && this.selectedFilterDateType === 'month') {
        this.selectedMonth = moment(this.selectedMonth).subtract(1, 'months').format('YYYY-MM');
      }
      this.reloadAreaChart("month");
    },
    nextMonth() {
      if(this.selectedMonth && this.selectedFilterDateType === 'month') {
        this.selectedMonth = moment(this.selectedMonth).add(1, 'months').format('YYYY-MM');
      }
      this.reloadAreaChart("month");
    },
    prevYear() {
      if(this.selectedYear && this.selectedFilterDateType === 'year') {
        this.selectedYear = moment(this.selectedYear).subtract(1, 'years').format('YYYY');
      }
      this.reloadAreaChart();
    },
    nextYear() {
      if(this.selectedYear && this.selectedFilterDateType === 'year') {
        this.selectedYear = moment(this.selectedYear).add(1, 'years').format('YYYY');
      }
      this.reloadAreaChart();
    },
    async reloadAreaChart(option) {
      this.isLoading = true;
      if (option === "date") {
        this.selectedDateText = moment(this.selectedDate).format("DD/MM/YYYY");
        // Update for month and year:
        this.selectedMonth = moment(this.selectedDate).format("YYYY-MM");
        this.selectedMonthText = moment(this.selectedDate).format("MM/YYYY");
        this.selectedYear = moment(this.selectedDate).format('YYYY') >= 2020 ? moment(this.selectedDate).format('YYYY') : moment().format('YYYY');
      }
      if (option === "month") {
        this.selectedMonthText = moment(this.selectedMonth).format("MM/YYYY");
        // Update for year:
        this.selectedYear = moment(this.selectedMonth).format('YYYY') >= 2020 ? moment(this.selectedMonth).format('YYYY') : moment().format('YYYY');
      }
      this.resetAnalyticChart();
      if(this.selectedYear && this.selectedFilterDateType === 'year') {
        this.selectedYear = moment(this.selectedYear).format('YYYY');
      }
      let startEndData = await this.getDateByDateType();
      let data = {
        start_time: startEndData.start_date,
        end_time: startEndData.end_date,
        site_id: this.$store.state.siteId ? this.$store.state.siteId : null,
        device_type: this.selectedFilterDeviceType,
      };
      if (this.selectedFilterDateType === 'day') {
        data.data_set = "minutes";
      }
      await dataAnalyticService
      .getDataAnalytic(data)
      .then((res) => {
        if (res.status == 200 && res?.data?.data) {
          this.chartDataAnalytic = res.data.data;
        } else {
          throw res;
        }
      })
      .catch((err) => {
        console.log(err);
      });
      this.setChartShapes();
      this.isLoading = false;
    },
    setChartShapes() {
      this.isFilterDayType = ["day"].includes(this.selectedFilterDateType) && ["pv", "evse", "home_load"].includes(this.selectedFilterDeviceType) ? true : false;
      this.isFilterLifeTimeType = ["life_time"].includes(this.selectedFilterDateType) && ["pv", "evse", "home_load"].includes(this.selectedFilterDeviceType) ? true : false;
      this.drawAnalyticCharts(this.isFilterDayType, this.isFilterLifeTimeType);
    },
    drawAnalyticCharts(isDayType, isLifeTimeType) {
      this.areaChartOptions = {
        chart: {
          stacked: isDayType ? true : (!["day"].includes(this.selectedFilterDateType) ? this.configChartStacked[this.selectedFilterDeviceType] : true),
          toolbar: {
            show: false,
          },
          zoom: {
            autoScaleYaxis: true
          }
        },
        grid: {
          show: true,
          borderColor: '#2C2C2E',
        },
        toolbar: {
          download: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 5
        },
        fill: {
          type: 'solid',
        },
        xaxis: {
          categories: [],
          labels: {
            rotateAlways: true,
            rotate: -45,
            minHeight: 45,
            style: {
              colors: '#8E8E93',
            },
          },
          crosshairs: {
            fill: {
              gradient: {
                opacityFrom: 0,
                opacityTo: 0,
              }
            }
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: ["#8E8E93"],
              fontSize: '12px',
            },
            formatter: function(val) {
              if (val !== null && val !== Infinity) {
                  if(Number.isInteger(val)) {
                    return val;
                  }
              } else {
                  return;
              }
            }
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
          y: {
            formatter: function (val) {
              if (val !== null && val !== Infinity) {
                  return val.toString();
              } else {
                  return;
              }
            }
          },
          hideEmptySeries: false,
        },
        legend: {
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: false
          },
          labels: {
            colors: '#8E8E93',
          },
          markers: {
            radius: 2,
          },
          formatter: function(seriesName) {
            let legendHTML, markerCSS;
            if (isDayType) {
                switch (seriesName) {
                    case 'PV':
                        legendHTML = `<svg width="10" height="4"><line x1="0" y1="1" x2="10" y2="1" style="stroke:#DAA931;stroke-width:4" /></svg> PV`;
                        markerCSS = `
                            <style>
                                .apexcharts-legend-series[rel="5"] .apexcharts-legend-marker[rel="5"] {
                                    display: none;
                                }
                            </style>
                        `;
                        return legendHTML + markerCSS;
                    case 'EV':
                        legendHTML = `<svg width="10" height="4"><line x1="0" y1="1" x2="10" y2="1" style="stroke:#2E6BA4;stroke-width:4" /></svg> EV`;
                        markerCSS = `
                            <style>
                                .apexcharts-legend-series[rel="4"] .apexcharts-legend-marker[rel="4"] {
                                    display: none;
                                }
                            </style>
                        `;
                        return legendHTML + markerCSS;
                    case 'Load':
                        legendHTML = `<svg width="10" height="4"><line x1="0" y1="1" x2="10" y2="1" style="stroke:#7030A0;stroke-width:4" /></svg> Load`;
                        markerCSS = `
                            <style>
                                .apexcharts-legend-series[rel="4"] .apexcharts-legend-marker[rel="4"] {
                                    display: none;
                                }
                            </style>
                        `;
                        return legendHTML + markerCSS;
                    default:
                        return seriesName;
                }
            } else {
                return seriesName;
            }
          }
        },
        markers: {
          size: 4,
        },
        colors: this.colorAreaChart[this.selectedFilterDeviceType],
      };
      if (this.chartDataAnalytic?.graphs?.length) {
        this.chartDataAnalytic.graphs.forEach(chart => {
          if (chart && chart.value && Array.isArray(chart.value)) {
            const nonNullValuesExist = chart.value.some(item => item && item.value !== undefined && item.value !== null);
            if (nonNullValuesExist) {
              const seriesData = chart.value.map(item => item.value);
              const seriesKey = chart.value.map(item => item.key);
              if(['bess' ,'grid'].includes(this.selectedFilterDeviceType)) {
                if(["day"].includes(this.selectedFilterDateType)) {
                  this.areaSeries.unshift({ name: this.xNameAreaChartObj[this.selectedFilterDeviceType][chart.key], data: this.getSeriesDataByMinutes(seriesData) })
                } else {
                  this.areaSeries.unshift({ name: this.xNameAreaChartObj[this.selectedFilterDeviceType][chart.key], data: seriesData })
                }
              } else {
                if(isDayType) {
                  this.areaSeries.push({ name: this.xNameAreaChartObj[this.selectedFilterDeviceType][chart.key], data: this.getSeriesDataByMinutes(seriesData), type: chart.key === this.selectedFilterDeviceType ? 'line' : 'area' })
                } else if (isLifeTimeType) {
                  this.areaSeries.push({ name: this.xNameAreaChartObj[this.selectedFilterDeviceType][chart.key], data: seriesData, type: chart.key === this.selectedFilterDeviceType ? 'line' : 'bar' })
                } else {
                  this.areaSeries.push({ name: this.xNameAreaChartObj[this.selectedFilterDeviceType][chart.key], data: seriesData, type: this.configChartTypes[this.selectedFilterDateType] })
                }
              }
              this.xaxisAnalyticSeriesLabel.push(seriesKey);
            }
          }
        });
      }
      this.areaChartOptions.xaxis.categories = this.xaxisAnalyticSeriesLabel?.length ? this.xaxisAnalyticSeriesLabel[0] : [];
      if (["month"].includes(this.selectedFilterDateType)) {
        this.areaChartOptions.xaxis.categories =  this.areaChartOptions.xaxis.categories.map(date => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'));
      }

      if (["year"].includes(this.selectedFilterDateType)) {
        this.areaChartOptions.xaxis.categories =  this.areaChartOptions.xaxis.categories.map(date => moment(date, 'YYYY-MM').format('MM/YYYY'));
      }

      if(['day'].includes(this.selectedFilterDateType)) {
        this.areaChartOptions.xaxis.type = 'datetime';
        this.areaChartOptions.xaxis.min = new Date().setUTCHours(0,0,0,0);
        this.areaChartOptions.xaxis.max = new Date().setUTCHours(23,59,59,999);
        this.areaChartOptions.xaxis.tickAmount = 60 * 24;
        this.areaChartOptions.xaxis.labels.format = 'HH:mm';
        this.areaChartOptions.tooltip.x.format = 'HH:mm';
        this.areaChartOptions.markers = {};
      }

      this.summaryChartOptions = {
        chart: {
          type: 'donut',
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70%'
            }
          }
        },
        stroke: {
          show: false
        },
        tooltip: {
          enabled: true,
        },
        colors: this.colorSummaryChart[this.selectedFilterDeviceType],
        labels: this.labelSummaryChart[this.selectedFilterDeviceType],
      };
      let disableSummary = 1;
      const allNullValues = this.chartDataAnalytic.summary.every(summary => summary.value === null || summary.value === 0);
      if (allNullValues) {
          this.summarySeries = [disableSummary];
          this.summaryChartOptions.tooltip.enabled = false;
          this.summaryChartOptions.colors = ['#585859'];
      } else {
          this.summarySeries = this.chartDataAnalytic.summary.map(summary => summary.value);
      }
    },
    updateFilterDeviceType() {
      localStorage.setItem('filter_device_type_localstorage', this.selectedFilterDeviceType);
      this.reloadAreaChart();
    },
    updateFilterDateType() {
      localStorage.setItem('filter_date_type_localstorage', this.selectedFilterDateType);
      this.reloadAreaChart();
    },
    hasValueGreaterThanZero(array) {
      return array.some(value => value > 0);
    },
    async getDateByDateType() {
      let dateType = this.selectedFilterDateType;
      let dateTimes = null;
      if(dateType === "day") {
        dateTimes = {
          start_date: moment(this.selectedDate).startOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
          end_date: moment(this.selectedDate).endOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
        };
      } else if(dateType === "month") {
        dateTimes = {
          start_date: moment(this.selectedMonth).startOf("month").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
          end_date: moment(this.selectedMonth).endOf("month").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
        }
      } else if (dateType === "year") {
        dateTimes = {
          start_date: moment(this.selectedYear).startOf("year").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
          end_date: moment(this.selectedYear).endOf("year").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
        }
      } else {
        dateTimes = {
          start_date: moment("2000-01-01").startOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
          end_date: moment().endOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT),
        }
      }
      return dateTimes;
    },
    resetAnalyticChart() {
      this.summarySeries = [];
      this.summaryChartOptions = {};
      this.areaSeries = [];
      this.areaChartOptions = {};
      this.xaxisAnalyticSeriesLabel = [];
    },
  },
  beforeDestroy() {
    if (this.socketData) {
      this.socketData.close();
    }
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
